<script lang="ts">
  import { spring } from "svelte/motion";
  import { map } from "@/lib/utils/common";
  import { expoInOut } from "svelte/easing";
  import { Color } from "@/lib/utils/color";
  import { onMount } from "svelte";
  import { twMerge } from "tailwind-merge";

  const MAX_RESULT = 3;
  const INIT_SPRING = 0;
  const END_SPRING = 1;
  const PARTICLE_COUNT = 100;

  let springs = [...Array(MAX_RESULT).keys()].map(() =>
    spring(INIT_SPRING, { stiffness: 0.15, damping: 0.2 }),
  );

  let bg_classes = Array(MAX_RESULT).fill(null);

  let target_particle_positions = [...Array(MAX_RESULT).keys()].map(() => {
    return Array(PARTICLE_COUNT)
      .fill(0)
      .map(() => randomSampleCircle({ x: 0, y: 0, r: 250 }))
      .map((pos) => ({
        opacity: 0,
        color: Color.fromHex("#F7E272").random_around(25, 0.1, 0.2),
        rotation: Math.random() * Math.PI * 8,
        scale: 0,
        ...pos,
      }));
  });

  let particle_positions = target_particle_positions.map((p) =>
    p.map((something) => ({
      ...something,
      x: 0,
      y: 0,
      opacity: 1,
      rotation: 0,
      scale: Math.random() * 3 + 0.5,
      color: something.color.setLuminance(1),
    })),
  );

  function triggerForward() {
    springs.forEach((spring, i) => {
      setTimeout(() => {
        spring.set(END_SPRING);
        bg_classes[i] = "star-bg-animate";
        particle_positions[i] = target_particle_positions[i]!;
      }, i * 500);
    });
  }

  function reset() {
    springs.forEach((spring, i) => {
      spring.set(INIT_SPRING);
      bg_classes[i] = null;
      particle_positions[i] = particle_positions[i]!.map((val) => ({
        ...val,
        scale: 0,
        opacity: 0,
        x: 0,
        y: 0,
        rotation: 0,
      }));
    });
  }

  function loopAnimation() {
    triggerForward();
    setTimeout(() => {
      reset();
      setTimeout(loopAnimation, 500); // Delay before restarting the animation
    }, 2500); // Duration of the forward animation
  }

  onMount(() => {
    loopAnimation();
  });

  $: styles = springs.map(() => getStyle(INIT_SPRING));

  $: {
    springs.forEach((spring, i) => {
      spring.subscribe((value) => {
        styles[i] = getStyle(value);
      });
    });
  }

  function getStyle(value: number) {
    const scale = map(value, INIT_SPRING, END_SPRING, 2.0, 1);
    return `transform: scale(${scale}) translate(-50%, -50%); opacity: ${expoInOut(value)}`;
  }

  type Circle = {
    x: number;
    y: number;
    r: number;
  };

  function randomSampleCircle(circle: Circle) {
    const angle = Math.random() * Math.PI * 2;
    const radius = Math.random() * circle.r;
    return {
      x: circle.x + Math.cos(angle) * radius,
      y: circle.y + Math.sin(angle) * radius,
    };
  }
</script>

<div class="flex grow flex-col items-stretch">
  <div class="flex h-full grow"></div>
  <div class="relative z-10 mb-16 flex justify-center gap-24 text-7xl">
    {#each Array(MAX_RESULT) as _, i}
      <div
        class={twMerge(
          i < MAX_RESULT ? "" : "opacity-25",
          "relative overflow-visible",
        )}
      >
        <div class="top-50 left-50 absolute">
          {#if i < MAX_RESULT}
            {#each particle_positions[i] ?? [] as p}
              <div
                class="particle bg-accent opacity-1 absolute h-2 w-2"
                style={`transform: translate(${p.x}px, ${p.y}px) rotate(${p.rotation}rad) scale(${p.scale}); background-color: rgba(${p.color.r}, ${p.color.g}, ${p.color.b}, ${p.color.a}); opacity: ${p.opacity};`}
              />
            {/each}
          {/if}
        </div>
        <div
          class="absolute size-12 -translate-x-1/2 -translate-y-1/2 bg-white"
        />
        <div
          class={twMerge(
            "star-bg absolute origin-center -translate-x-1/2 -translate-y-1/2",
            bg_classes[i],
          )}
        >
          ⭐
        </div>
        <div
          class={twMerge("absolute", i >= springs.length ? "hidden" : "")}
          style={styles[i]}
        >
          ⭐
        </div>
      </div>
    {/each}
  </div>
  <div class="flex h-full grow"></div>
</div>

<style>
  .justify {
    padding: 2rem;
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
    overflow: visible; /* Changed from hidden to visible */
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .particle {
    opacity: 0;
    transition-property: transform opacity background-color;
    transition-delay: 210ms;
    transition-behavior: smooth;
    transition-timing-function: cubic-bezier(0.05, 0.6, 0, 0.99);
    transition-duration: 2s;
  }

  .star-bg {
    opacity: 0.35;
  }

  .star-bg-animate {
    animation-name: star-bg--animation;
    animation-delay: 0.2s;
    animation-duration: 0.7s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    transform-origin: center;
  }

  @keyframes star-bg--animation {
    0% {
      transform: translate(-50%, -50%) scale(0.5);
      filter: blur(0px);
      opacity: 0;
    }
    40% {
      transform: translate(-50%, -50%) scale(4.5);
      filter: blur(10px);
      opacity: 0.5;
    }
    99% {
      transform: translate(-50%, -50%) scale(9);
      filter: blur(32px);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(0.5);
      filter: blur(32px);
      opacity: 0;
    }
  }
</style>
